var select = document.getElementById( 'langList' );

if(select!= null){
  select.onchange = function()
    {
      var selectedItem = this.options[ this.selectedIndex ];
      translate(selectedItem.value );
    }
}
var ja = document.getElementsByClassName('ja');
var en = document.getElementsByClassName('en');

for(var i = 0 ; i < ja.length ; i++){
  en[i].addEventListener('click', toEN, false);
  ja[i].addEventListener('click', toJP, false);
}


function toEN() {
  translate("en");
}

function toJP() {
  translate("ja");
}

function translate(lang){
	if(lang=="en")document.getElementById("about").children.item(1).innerHTML
	 = '<div class="contentArea"><h2>ABOUT</h2><p>SHITCOIN was born in 2019.</p><p>SHITCOIN is based on MONACOIN, and the function of NAMECOIN has been added.</p><p>Merge mining with MONACOIN is possible.</p><p>Let`s enjoy the playful Internet with .shit domain.</p></div>';

	if(lang=="ja")document.getElementById("about").children.item(1).innerHTML
	 = '<div class="contentArea"><h2>ABOUT</h2><p>SHITCOINは2019年に誕生しました。</p><p>MONACOINをベースにNAMECOINの機能を追加してあります。</p><p>MONACOINとのマージマイニングが可能です。</p><p>おバカなインターネットを.shitドメインで楽しみましょう。</p></div>';
}

translate("en")
